/* AboutCom.css */
.main-com {
  background: white;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

}

.about-us-container {
  color: black;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;


  margin-bottom: 100px;
}

.H1, .H2 {
  text-align: center;
}

.H1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
  margin-top: 7rem;
}

.H2 {
  font-size: 1.8em;
  margin-bottom: 1em;
}

.H3 {
  color: black;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.about-us-section {
  margin-bottom: 20px;
}

.about-us-section ul {
  list-style-type: none;
  padding: 0;
}

.about-us-section li {
  margin-bottom: 10px;
}

.cont-button {
  background-color: rgb(254, 86, 24);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 1em;
  margin-top:50px;
}

.cont-button:hover {
  background-color: rgb(255, 107, 62);
}



/* Responsive Design */
@media (max-width: 600px) {
  .about-us-container {
    padding: 10px;
  }

  .H1 {
    font-size: 1.8em;
  }

  .H2 {
    font-size: 1.5em;
  }

  .H3 {
    font-size: 1.2em;
  }

  .cont-button {
    width: 100%;
    padding: 15px;
    font-size: 1.2em;
  }
}
