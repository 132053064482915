/* .a {
  height: 100vh;
  display: flex;
  align-items: center;
  background: white;
  margin-bottom: 5%;
  margin-top: 20px
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;

  border-right: 2px solid rgb(76, 76, 73);
  box-shadow: 2px 0 7px rgba(0, 0, 0, 0.3);
}


.a-right {
  flex: 1;
  margin-right: 17vh;

   margin-left: 1rem;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 6px 10px 16px rgba(0, 0, 0, 1);
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.a-card:hover,
.a-card:hover .a-img {
  transform: scale(1.05);
  box-shadow: 6px 10px 16px rgba(0, 0, 0, 1);
}

.a-award-img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
}

.a-title {
  font-weight: 400;
  cursor: pointer;
  color: black;
  margin-right: 50%;
  margin-bottom: -3px;
}

.a-title:hover {
  transform: scale(1.03);
  transition: transform 0.5s ease;
}

.a-sub {
  margin: 20px 0px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.a-desc {
  margin-top: 30px;
  font-weight: 350;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.a-award {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a-award-texts {
  width: 70%;
}

.a-award-title {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}
.btn2 {
  background-color: rgb(255, 68, 0); 
  border: none;
  color: white; 
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  width:auto;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.btn2:hover {
  background-color: rgb(253, 123, 76); 
}

@media screen and (max-width: 768px) {
  .a-left {
    margin-top: 20px;
  }

  .a-right {
    margin-right: 10vh;
  }

  .a-card {
    width: auto;
    height: auto;
  }

  .a-title {
    margin-right: 30%;
  }

  .a-desc {
    font-size: 12px;
  }
  .a {
    flex-direction: column;
    
    margin-top: 50px;
  }


  .a-award {
    margin-top: 30px;
  }
}

@media screen and (max-width: 480px) {
  .a {
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    height: 100%;
  }

  .a-left {
    width: 100%;
    margin-top: 20px;
   
  }

  .a-right {
    margin-right: 0;
    padding: 20px;
    padding-bottom:30px;
 
  }

  .a-card {
    width: 90%;
    height: 30vh;
  }

  .a-card.bg,
  .a-award {
    display: none;
  }

  .a-title {
    margin-right: 0;
    font-size: 20px;
  }

  .a-sub {
    font-size: 14px;
  }

  .a-desc {
    font-size: 12px;
  }

  .a-award-title {
    font-size: 14px;
  }

  .a-award-img {
    width: 100px;
    height: 100px;
  }
} */

.a {
  height: 100vh;
  display: flex;
  align-items: center;
  background: white;
  padding: 0 2rem;
  margin-top: 20px;
}

/* .a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  border-right: 2px solid rgb(76, 76, 73);
 
} */
.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  border-right: 2px solid rgb(76, 76, 73);
  }


.a-right {
  flex: 1;
  margin-right: 10vh;
  padding-left: 3rem;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 6px 10px 16px rgba(0, 0, 0, 1);
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.a-card:hover,
.a-card:hover .a-img {
  transform: scale(1.05);
  box-shadow: 6px 10px 16px rgba(0, 0, 0, 1);
}



.a-title {
  font-weight: 400;
  cursor: pointer;
  color: black;
  margin-right: 50%;
  margin-bottom: 3px;
}

.a-title:hover {
  transform: scale(1.03);
  transition: transform 0.5s ease;
}

.a-sub {
  margin: 20px 0;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.a-desc {
  margin-top: 30px;
  font-weight: 350;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.a-award {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a-award-texts {
  width: 70%;
}

.a-award-title {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.btn2 {
  background-color: rgb(255, 68, 0);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  width: auto;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

  

.btn2:hover {
  background-color: rgb(255, 111, 65);
}

@media screen and (max-width: 1024px) {
  .a-sub{
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .a {
    flex-direction: column;
    height: auto;
    margin-top: 50px;
    padding-bottom: 3rem;
  }

  .a-left {
    margin-top: 20px;
    width: 100%;
    border-right: none;
    box-shadow: none;
  }

  .a-right {
    margin-right: 10vh;

  }

  .a-card {
    width: 80%;
    height: auto;
    margin-bottom: 20px;
  }

  .a-title {
    margin-right: 30%;
    font-size: 17px;
    
  }

  .a-desc {
    font-size: 10px;
  }

  .a-award {
    margin-top: 30px;
  }

}

@media screen and (max-width: 480px) {
  .a {
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    padding: 2rem;
  }

  .a-left {
    width: 100%;
    margin-top: 20px;
    border:none;
  }

  .a-right {
    margin-right: 0;
    padding: 20px;
    padding-bottom: 30px;
  }

  .a-card {
    width: 90%;
    height: 30vh;
  }

  .a-card.bg,
  .a-award {
    display: none;
  }

  .a-title {
    margin-right: 0;
    font-size: 20px;
  }

  .a-sub {
    font-size: 14px;
  }

  .a-desc {
    font-size: 12px;
  }

  .a-award-title {
    font-size: 14px;
  }

  .a-award-img {
    width: 100px;
    height: 100px;
  }
}
