.navbar {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%; 
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  color: black;
  padding: 0 1rem;
  z-index: 1000; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}



.l-n {
  font-weight: 600;
  margin-left: 3rem;
  font-size: 1.6rem;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  margin-left: 1.5rem;
}

.navbar-menu {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.navbar-item {
  margin-right: 1rem;
}

.navbar-link {
  text-decoration: none;
  color: black;
  font-weight: 450;
  padding: 10px 20px;
  border-radius: 0.25rem;
}

.navbar-link:hover {
  border-radius: 30px;
  padding: auto;
  background-color: rgb(245, 114, 57);
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: auto;
}
@media (max-width: 1024px) {
  .l-n {
    margin-left: 2rem;
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .l-n {
    margin-left: 1.5rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .l-n {
    margin-left: 1rem;
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 12vh;
    left: 0;
    width: 85%;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.8);
    transition: transform 0.3s ease;
    transform: translateY(-100%);
    z-index: 1000;
    margin-left: 2rem;
  }
 

  .navbar-menu.active {
    display: flex;
    transform: translateY(0);
  }

  .navbar-item {
    margin: 0.5rem 0;
    text-align: center;
  }

  .navbar-link {
    font-size: 1.2rem;
  }

  .hamburger {
    display: block;
  }
}
