/* .contact-us-container {
  font-family: "Poppins", sans-serif;
  color: #080808;
  padding: 10%;
  box-sizing: border-box;
}

.contact-main {
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 8%;
}

.head h1 {
  top: 0;
  text-align: center;
}

.head {
  padding-bottom: 50px;
  text-align: center;
}

.c-left,
.c-right {
  flex: 1;
  padding: 2rem 1rem;
}

.c-left {
  margin-right: 0;
}

.contact-us-container h1,
.contact-us-container h2,
.contact-us-container h3 {
  color: #000000;
  margin-bottom: 20px;
  text-align: center;
}

.contact-item p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.contact-methods {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.contact-item {
  padding: 15px;
  border-radius: 5px;
}

.contact-item p {
  margin: 5px 0;
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.business-hours {
  margin-top: 20px;
}

@media (min-width: 600px) {
  .contact-main {
    flex-direction: row;
  }

  .contact-methods {
    flex-direction: row;
    justify-content: space-between;
  }

  .contact-item {
    flex: 1;
    margin: 0 10px;
  }
}

@media (max-width: 599px) {
  .contact-us-container {
    padding: 10px;
  }

  .contact-methods {
    gap: 10px;
  }

  .contact-item {
    padding: 10px;
  }

  .c-left,
  .c-right {
    padding: 1rem;
    margin: 0;
  }
}

.c-t {
  padding: 2.5rem;
  margin: 0 auto;
  background-color: white;
  border-radius: 0.625rem;
  box-shadow: 0.1875rem 0.3125rem 0.5625rem rgba(0, 0, 0, 0.9);
  width: 70%;
  border: 1px solid rgb(64, 64, 64);
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

input,
textarea {
  width: 100%;
  margin: 0.625rem 0;
  padding: 0.625rem;
  border: 0.0625rem solid #ddd;
  border-radius: 0.3125rem;
  font-size: 1rem;
}

textarea {
  height: 9.375rem;
  resize: vertical;
}

.btn5 {
  border: none;
  padding: 0.9375rem;
  background-color: rgb(255, 93, 34);
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1rem;
  width: auto;
}

.btn5:hover {
  background-color: rgb(253, 114, 63);
}

@media screen and (max-width: 1300px) {
  .c-t {
    width: auto;
    padding: 1.875rem;
    margin: 0 auto;
  }

  .btn5 {
    width: 5.625rem;
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 480px) {
  .c {
    margin-top: 1.25rem;
  }

  .c h1 {
    font-size: 1.25rem;
    margin-bottom: 1.875rem;
  }

  .c-t {
    width: 90%;
    padding: 1.25rem;
  }

  form {
    padding-right: 3px;
    width: 100%;
  }

  input,
  textarea {
    font-size: 0.875rem;
  }

  .btn5 {
    width: 5rem;
    font-size: 0.75rem;}
     */

     .contact-us-container {
      font-family: "Poppins", sans-serif;
      color: #080808;
     
      box-sizing: border-box;
    }
    
    .contact-main {
      background: white;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 8%;
      margin-left:1rem;
      margin-top: 5%;
    }
    
    .head h1 {
      top: 0;
      text-align: center;
    }
    
    .head {
      margin-top:10rem;
      padding-bottom: 10px;
      text-align: center;
    }
    
   
    .c-right {
      flex: 1;
   
      padding-left:5rem ;
    }
    .c-left{
      padding-left:3rem ;
      flex:1;
      margin-left:4rem;
    }
    .c-left {
      margin-right: 20px;
    }
    
    .contact-us-container h1,
    .contact-us-container h2,
    .contact-us-container h3 {
      color: #000000;
      margin-bottom: 20px;
      text-align: center;
    }
    
    .contact-item p {
      line-height: 1.6;
      margin-bottom: 20px;
    }
    
    .contact-methods {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;
    }
    
    .contact-item {
      padding: 15px;
      border-radius: 5px;
    }
    
    .contact-item p {
      margin: 5px 0;
    }
    
    a {
      color: #000000;
      text-decoration: none;
    }
    
    a:hover {
      text-decoration: underline;
    }
    
    .business-hours {
      margin-top: 20px;
    }
    
    @media (min-width: 600px) {
      .contact-main {
        flex-direction: row;
      
      }
    
      .contact-methods {
        flex-direction: row;
        justify-content: space-between;
      }
    
      .contact-item {
        flex: 1;
        margin: 0 auto;
      }
    }
    
    @media (max-width: 599px) {
      .contact-us-container {
        padding: 10px;
      }
    
      .contact-methods {
        gap: 10px;
      }
    
      .contact-item {
        padding: 10px;
      }
    
      .c-left,
      .c-right {
        padding: 1rem;
        margin: 0;
      }
    }
    
    .c-t {
      padding: 2.5rem;
      margin-left: 2rem;
      background-color: white;
      border-radius: 0.625rem;
      box-shadow: 0.1875rem 0.3125rem 0.5625rem rgba(0, 0, 0, 0.9);
      width: 70%;
      height:auto;
      border: 1px solid rgb(64, 64, 64);
    }
    
    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    
    input,
    textarea {
      width: 100%;
      margin: 0.625rem 0;
      padding: 0.625rem;
      border: 0.0625rem solid #ddd;
      border-radius: 0.3125rem;
      font-size: 1rem;
    }
    
    textarea {
      height: 9.375rem;
      resize: vertical;
    }
    
    .btn5 {
      border: none;
      padding: 0.9375rem;
      background-color: rgb(255, 93, 34);
      color: white;
      font-weight: 600;
      cursor: pointer;
      border-radius: 10px;
      font-size: 1rem;
      width: auto;
    }
    
    .btn5:hover {
      background-color: rgb(253, 114, 63);
    }
    
    @media screen and (max-width: 764px) {
      .c-t {
        width: auto;
        padding: 2rem;
        margin:  auto;
        height:auto;
      }
    
      .btn5 {
        width: 5.625rem;
        font-size: 0.875rem;
      }
    }
    
    @media screen and (max-width: 480px) {
      .c {
        margin-top: 1.25rem;
      }
    
      .c h1 {
        font-size: 1.25rem;
        margin-bottom: 1.875rem;
      }
    
      .c-t {
        width: 90%;
        padding: 1.25rem;
      }
    
      form {
        padding-right: 3px;
        width: 100%;
      }
    
      input,
      textarea {
        font-size: 0.875rem;
      }
    
      .btn5 {
        width: 5rem;
        font-size: 0.75rem;
      }
    }
    