.staffing-cards {
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    }
  .ser{
    background: linear-gradient(rgb(253, 253, 255),rgb(250, 249, 242));
margin-top: 3rem;
 
  }

  .ser, .t-1, h1 {
    text-align: center;
  }
  
  .t-1 {
    padding-bottom: 40px;
  }
  .t-1, h1{
    margin-top: 70px;
  }
  .t-1, h3{
    padding: 0 20px;
  }
  .cards-container{
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    justify-content: center;
    margin: 40px;
    margin-top: 60px;
  
  }
  .cards-container1 
  {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    justify-content:space-evenly ;
    padding: 40px;
    gap: 60px;

  }
  
  .cards-container1 {
    width: 100%;
  }
  
  .card {
    background: #fff;
    
    border-radius: 10px;
    box-shadow:  5px 9px 16px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgb(47, 47, 47);
  }
  
  .card:hover {
    transform: translateY(-20px);
    box-shadow: 4px 11px 26px rgba(0, 0, 0, 0.9);
    
  }
  
  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    box-shadow:  2px 5px 8px rgba(0, 0, 0, 0.9);
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-content h2 {
    margin: 0 0 10px;
    font-size: 1.1em;
  }
  
  .card-content p {
    margin: 0;
    font-size: 0.8em;
    color: #666;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    border: 1px solid black;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    box-shadow: 4px 10px 17px black(0, 0, 0, 1);
    position: relative;

  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .popup-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow:  2px 5px 8px rgba(0, 0, 0, 0.9);
  }
  
  .popup-details {
    text-align: center;
  }
  
  .popup-details h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
  }
  
  .popup-description {
    color: #666;
    font-size: 1em;
    margin-bottom: 0;
  }
  
  .why-choose {
    text-align: center;
    margin: 40px 0;

  }
  
  .why-choose h2 {
    font-size: 2em;
    margin-bottom: 20px;
    margin-top: 100px;
  }
  
  .why-choose ul {
    list-style-type: none;
    padding: 0;
  }
  
  .why-choose ul li {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
  
  .contact-us {
    text-align: center;
    margin: 40px 0;
  }
  
  .contact-us h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .contact-us p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  
  .contact-button {
    background-color:rgb(252, 83, 21);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: rgb(255, 104, 48);
  }
  
  @media (max-width: 768px) {
    .card-content h2 {
      font-size: 1.1em;
    }
  
    .card-content p {
      font-size: 0.8em;
    }
  
    .popup-image {
      height: 200px;
    }
  
    .popup-details h2 {
      font-size: 1.2em;
    }
  
    .popup-description {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 576px) {
    .cards-container, .cards-container1 {
      flex-direction: column;
      align-items: center;
    }
   
    .card {
      max-width: auto;
      height:auto;
      margin-bottom: 20px;
    }
  
    .popup-content {
      width: 95%;
    }
  
    .popup-image {
      height: 150px;
    }
  
    .popup-details h2 {
      font-size: 1.1em;
    }
  
    .popup-description {
      font-size: 0.8em;
    }
  }
  