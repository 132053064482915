
.i {
  position: relative;
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; 
  background-size: cover;
}

.i-left {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  text-align: center; 
  margin: 0 10px; 
}

.i-left-wrapper {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.i-intro {
  font-weight: 300;
  font-size: 25px;
  color: black;
  margin: 0;
}

.i-name {
  font-size: 80px;
  color: black;
  font-weight: 300;
  margin: 0;
}

.i-desc {
  font-family: "Poppins", sans-serif;
  color: black;
  font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
  padding: 0 40px; 
}

.i-title {
  height: 50px;
  overflow: hidden;
  padding: 30px;
}

.i-title-wrapper {
  font-size: 20px;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.i-title-item {
  height: 60px;
  font-size: 25px;
  font-weight: 300;
  color: black;
  display: flex;
  align-items: center;
}

.i-scroll {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 20px;
}
.i-name span{
  color: red;
  font-weight: 500;

}
.i-img {
  filter: drop-shadow(9px 14px 17px rgb(0, 0, 0, 0.8));
  width: 70%;
  height: 50%;
  object-fit: cover;
  padding: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.btn {
  background-color: rgb(255, 68, 0);
  margin-top: 30px;
  color: #fff;
  border: none;
  border-radius: 11px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: rgb(251, 108, 56);
}

.i-bg {
  clip-path: circle(79.3% at 72% 100%);
  filter: drop-shadow(1px 6px 9px rgb(0, 0, 0, 0.7));
  background-color: rgb(185, 248, 252);
  width: 70%;
  height: 50%;
  position: absolute;
  top: 32%;
  right: 35px;
}

.i-img:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1024px) {
  .i-left-wrapper {
   margin:0 20px;
    margin-top: 40px;
  }
  .i-intro {
    font-size: 24px;
  }
  .i-name {
    font-size: 60px;
  }
  .i-desc {
    font-size: 18px;
    padding: 15px;
  }
  .i-title-item {
    font-size: 24px;
  }
  .btn {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 14px;
  }
  .i-img {
    width: auto;
    height: auto;
  }
  .i-bg {
    width: auto;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .i {
    flex-direction: column;
  }
 
  .i-left-wrapper {
    margin-top: 50px;
    margin:0 20px;
    align-items: center;
    text-align: center;
  }
  .i-intro {
    font-size: 20px;
  }
  .i-name {
    font-size: 45px;
  }
  .i-desc {
    font-size: 16px;
    padding: 10px;
    margin-top: 10px;
  }
  .i-title {
    padding: 20px;
  }
  .i-title-item {
    font-size: 20px;
  }
  .btn {
    padding: 8px 16px;
    font-size: 12px;
  }
  .i-img {
    width: 80%;
    height: 70%;
  }
  .i-bg {
    top: 60px;
    width: 60%;
    height: 70%;
  }
}

/* 
.cover {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: white;
  overflow: hidden;
}

.i {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: calc(100vh - 5%);
  color: black;
}

.i-left {
  margin-top: 15rem;
  max-width: 90%;
  padding: 20px;
  text-align: center;
}

.i-name {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.i-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.i-desc {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.ocean {
  height: 0%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #e4f7fc;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) ;
  position: absolute;
  top: -150px;
  width: 6400px;
  height: 150px;
  animation: wave 0s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -100px;
  animation: wave 0s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/* Media Queries for Responsiveness 

@media (max-width: 1200px) {
  .i-name {
    font-size: 1.8rem;
  }

  .i-title {
    font-size: 1.3rem;
  }

  .i-desc {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .i-name {
    font-size: 1.6rem;
  }

  .i-title {
    font-size: 1.2rem;
  }

  .i-desc {
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  .i-left {
    max-width: 100%;
  }

  .i-name {
    font-size: 1.4rem;
  }

  .i-title {
    font-size: 1.1rem;
  }

  .i-desc {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .i {
    flex-direction: column;
  }

  .i-name {
    font-size: 1.2rem;
  }

  .i-title {
    font-size: 1rem;
  }

  .i-desc {
    font-size: 0.75rem;
  }
} */
