/* .main-container {
  background: rgb(20, 20, 20);
  background: white;

  font-family: "Poppins", sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding-bottom: 10%;
}

.pl-title {
  font-size: 50px;
  font-weight: 600;
  text-align: center;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
}

.card1, .card2, .card3, .card4 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 60px;

  box-shadow:7px 15px 25px rgb(0,0,0,1);
  height: 450px;
  width: 350px;
  margin: 30px;
  background-color: rgb(251, 248, 228);
  z-index: 0;
  position: relative;
  border-radius: 1.1rem;
}

.content1, .content2, .content3, .content4 {
  margin: 20px;
  padding: 20px;
  font-size: 25px;
}

.card1::after, .card2::after, .card3::after, .card4::after {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
  opacity: 0.7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: height 0.6s;
  border-radius: 1rem;
}

.card2::after {
  background: linear-gradient(rgb(255, 255, 255),rgb(187, 243, 255));
}

.card1::after {
  background: linear-gradient(rgb(255, 255, 255),rgb(187, 243, 255));
}

.card3::after {
  background: linear-gradient(rgb(255, 255, 255),rgb(187, 243, 255));
}



.card1:hover::after, .card2:hover::after, .card3:hover::after, .card4:hover::after {
  height: 0px;
  border-radius: 1rem 1rem 0 0;
}

.content1 h3, .content2 h3, .content3 h3, .content4 h3 {
  position: absolute;
  top: 5em;
  left: 1rem;
  transition: 0.8s;
  font-weight: 600;
  font-size: 30px;
}

.content1 p, .content2 p, .content3 p, .content4 p {
  display: block;
  position: absolute;
  top: 70px;
  left: 40px;
  right: 40px;
  font-size: 15px;
  font-weight: 300;
  opacity: 0;
  transition: opacity 0.6s;
}

.card-btn {
  position: absolute;
  bottom: 20px;
  width: auto;
  left: 38.5%;
  background-color: orangered;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-btn:hover {
  cursor: pointer;
}

.card1:hover .content1 p, .card2:hover .content2 p, .card3:hover .content3 p, .card4:hover .content4 p {
  display: block;
  position: absolute;
  top: 80px;
  left: 40px;
  right: 40px;
  font-size: 20px;
  opacity: 0.9;
}

.card1:hover .content1 h3, .card2:hover .content2 h3, .card3:hover .content3 h3, .card4:hover .content4 h3 {
  position: absolute;
  top: 10px;
  
}





.mai h1 {
  color: black;
}





@media screen and (max-width: 768px) {
  .pl-title {
    font-size: 40px;
  }
  .main-container {

    
    height: 100%;
    width: 100%;
  }
  .main {
gap: 20px;
    display: flex;
    flex-wrap:wrap;
  margin-top: 80%;
  
    left: 50%;
    transform: translate(-50%, -30%);
  }

  .card1, .card2, .card3 {
    gap:30px;
    width: 200px;
    height: 300px;
    margin: 15px;
  }

  .content1, .content2, .content3 {
    margin: 15px;
    padding: 20px;
    font-size: 20px;
  }

  .content1 p, .content2 p, .content3 p {
    font-size: 16px;
  }

  .card-btn {
    padding: 8px 16px;
    font-size: 14px;
    left: 35%;
  }

  .swiper-slide {
    width: 400px;
    height: 500px;
  }
}

@media screen and (max-width: 480px) {
  .pl-title {
    font-size: 35px;
  }

  .main {
    gap: 70px;
    display: flex;
    flex-wrap:wrap;
  margin-top: 120%;
  
    left: 50%;
    transform: translate(-50%, -30%);
 
  }

  .card1, .card2, .card3{
    gap:2px;
    width: 300px;
    height: 400px;
    margin: 15px;
  }

  .content1, .content2, .content3 {
    margin: 15px;
    padding: 10px;
    
  }
  .content1 h3, .content2 h3, .content3 h3 {
    font-size: 24px;
  }
  .content1 p, .content2 p, .content3 p {
    font-size: 2px;
    display: block;
  }



 
} */
.main-container {
  background: white;
  font-family: "Poppins", sans-serif;
  display: flex;
 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 10%;
}
.main{
  gap: 40px;
}

.pl-title {
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  position: relative;
  padding: 0;
}

.card1, .card2, .card3, .card4 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 7px 15px 25px rgba(0, 0, 0, 0.5);
  height: 410px;
  width: 350px;
  margin: 15px;
  background-color: rgb(251, 248, 228);
  z-index: 0;
  position: relative;
  border-radius: 1.1rem;
  overflow: hidden;
}

.content1, .content2, .content3, .content4 {
  margin: 20px;
  padding: 20px;
  font-size: 25px;
}

.card1::after, .card2::after, .card3::after, .card4::after {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
  opacity: 0.7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: height 0.6s;
  border-radius: 1rem;
}

.card1::after, .card2::after, .card3::after {
  background: linear-gradient(rgb(255, 255, 255), rgb(187, 243, 255));
}

.card1:hover::after, .card2:hover::after, .card3:hover::after, .card4:hover::after {
  height: 0px;
  border-radius: 1rem 1rem 0 0;
}

.content1 h3, .content2 h3, .content3 h3, .content4 h3 {
  position: absolute;
  top: 5em;
  left: 1rem;
  transition: 0.8s;
  font-weight: 600;
  font-size: 26px;
}

.content1 p, .content2 p, .content3 p, .content4 p {
  display: block;
  position: absolute;
  top: 70px;
  left: 40px;
  right: 40px;
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  transition: opacity 0.6s;
  padding-top: 20px;
}

.card-btn {
  position: absolute;
  bottom: 20px;
  width: auto;
  left: 38.5%;
  background-color: orangered;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-btn:hover {
  background-color: darkorange;
}

.card1:hover .content1 p, .card2:hover .content2 p, .card3:hover .content3 p, .card4:hover .content4 p {
  opacity: 0.9;
}

.card1:hover .content1 h3, .card2:hover .content2 h3, .card3:hover .content3 h3, .card4:hover .content4 h3 {
  top: 10px;
}

@media screen and (max-width: 768px) {
  .pl-title {
    font-size: 40px;
  }

  .main {
    gap: 20px;
    margin-top: 0;
    transform: translate(0, 0);
  }

  .card1, .card2, .card3, .card4 {
    width: 300px;
    height: 400px;
    margin: 15px;
  }

  .content1, .content2, .content3, .content4 {
    font-size: 20px;
  }

  .card-btn {
    padding: 8px 16px;
    font-size: 14px;
    left: 35%;
  }
}

@media screen and (max-width: 480px) {
  .pl-title {
    font-size: 35px;
  }

  .main {
    gap: 30px;
    margin-top: 0;
    transform: translate(0, 0);
  }

  .card1, .card2, .card3, .card4 {
    width: 280px;
    height: 300px;
    margin: 10px;
  }

  .content1, .content2, .content3, .content4 {
    font-size: 18px;
  }

  .content1 h3, .content2 h3, .content3 h3, .content4 h3 {
    font-size: 21px;
  }

  .content1 p, .content2 p, .content3 p, .content4 p {
    font-size: 13px;
    padding-top: 10px;
  }

}
