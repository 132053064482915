/* General Container Styles */
.resources {
  padding: 20px;
  max-width: 800px;
  
  margin: 0 auto;
}

/* Background and Layout for Main Section */
.resmain {
 padding-bottom: 10%;
 background: white;
 margin-top: 3rem;
  width: 100%;
  height: 100%;
}

/* Intro Section */
.intro {
  text-align: center;
  margin-bottom: 40px;
}

.intro h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: black; /* Adjust color to ensure visibility against background */
}

.intro p {
  font-size: 1.2em;
  color:black; /* Adjust color for readability */
}

/* Insights Section */
.insights {
  margin-top: 20px;
}

.insights h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
  color:black; /* Adjust color to ensure visibility against background */
}

.insights ul {
  list-style-type: disc;
  margin: 0;
  padding: 0;
  padding-left: 20px;
}

.insights li {
  font-size: 1.1em;
  margin-bottom: 15px;
  color:black; /* Adjust color for readability */
}

.insights p {
  font-size: 1.1em;
  text-align: center;
  margin-top: 20px;
  color:black; /* Adjust color for readability */
}

/* Responsive Design */
@media (max-width: 768px) {
  .intro h1 {
    font-size: 1.5em;
  }

  .intro p, .insights p {
    font-size: 1em;
  }

  .insights h2 {
    font-size: 1.5em;
  }

  .insights li {
    font-size: 1em;
  }
}

@media (max-width: 576px) {
  .intro h1 {
    font-size: 1.2em;
  }

  .intro p, .insights p {
    font-size: 0.9em;
  }

  .insights h2 {
    font-size: 1.2em;
  }

  .insights li {
    font-size: 0.9em;
  }
}
