/* src/components/Footer.css
.Footer {
    padding: 1rem 0;

    background-image: linear-gradient(to top, #151515 0%, #202020 100%);
  }
  
  .Footer .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  
  .Footer .row {
    padding-left:10rem ;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .ft-1, .ft-2, .ft-3 {
    flex: 1 1 30%;
    padding: 1rem;
    margin: 1rem 0;
  }
  
  .ft-1 h3 {
    font-weight: 700;
    font-family: cursive;
    letter-spacing: 2px;
    padding: 0.5rem 0;
  }
  
  .ft-1 span {
    color: #f60838;
    font-weight:600;
  }
  
  .ft-1 p, .ft-2 p, .ft-3 p {
    color: white; 
    padding: 0rem 1rem 0.8rem 0;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
  
  .footer-icons {
    display: flex;
    justify-content: flex-start;
  }
  
  .footer-icons i {
    padding: 0.4rem 0.5rem;
    background: #e1e1e1;
    color: #f60838;
    margin: 0 0.5rem;
    border-radius: 50%;
  }
  
  .footer-icons i:hover {
    background: #f60838;
    color: #fff;
    transition: 0.6s;
    cursor: pointer;
  }
  
  .Footer h5 {
    color: #f60838;
  }
  
  .ft-2 ul {
    list-style: none;
    padding-left: 0;
  }
  
  .ft-2 ul li {
    padding: 0.35rem 0;
    font-weight: 500;
  }
  
  .ft-2 ul a {
    text-decoration: none;
    color: #f9f7f7;
    font-size: 1.06rem;
  }
  
  .ft-3 p {
    font-weight: 500;
    padding: 0.1rem 0;
    font-size: 1.06rem;
  }
  
  .ft-3 i {
    padding-right: 0.5rem;
  }
  
  .Last-footer {
    background: #313131;
    text-align: center;
    color: white;
    padding: 1rem 0;
  }
  
  .Last-footer p {
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 700;
  }
  

  @media (max-width: 992px) {
    .Footer .container {
      flex-direction: column;
      align-items: center;
    }
  
    .ft-1, .ft-2, .ft-3 {
      flex: 1 1 100%;
      margin: 1rem 0;
      text-align: center;
    }
  
    .footer-icons {
      justify-content: center;
    }
  }
  
  @media (max-width: 576px) {
    .ft-1 p, .ft-2 ul a, .ft-3 p {
      font-size: 1rem;
    }
  
    .footer-icons {
      flex-wrap: wrap;
    }
  
    .Last-footer p {
      font-size: 1rem;
    }
  }
   */


   /* src/components/Footer.css */
.Footer {
  padding: 1rem 0;
  background-image: linear-gradient(to top, #151515 0%, #202020 100%);
}

.Footer .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.Footer .row {
  padding-left: 10rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

 .ft-2, .ft-3 {
  flex: 1 1 30%;
  padding: 1rem;
  margin: 1rem 0;
}
.ft-1{
  padding: 2rem;
  margin-right: 1rem ;
  flex: 1 1 30%;
}
.ft-1 h3 {
  font-weight: 700;
  font-family: cursive;
  letter-spacing: 2px;
  padding: 0.5rem 0;
}

.ft-1 span {
  color: #f60838;
  font-weight: 500;
  font-family: cursive;
}

.ft-1 p, .ft-2 p, .ft-3 p {
  color: white;
  padding: 0 1rem 0.8rem 0;
  font-weight: 400;
  font-family: cursive;
}

.footer-icons {
  display: flex;
  justify-content: flex-start;
}

.footer-icons i {
  padding: 0.4rem 0.5rem;
  background: #e1e1e1;
  color: #f60838;
  margin: 0 0.5rem;
  border-radius: 50%;
}

.footer-icons i:hover {
  background: #f60838;
  color: #fff;
  transition: 0.6s;
  cursor: pointer;
}

.Footer h5 {
  color: #f60838;
}

.ft-2 ul {
  list-style: none;
  padding-left: 0;
}

.ft-2 ul li {
  padding: 0.35rem 0;
  font-weight: 500;
}

.ft-2 ul a {
  text-decoration: none;
  color: #f9f7f7;
  font-size: 1.06rem;
}

.ft-3 p {
  font-weight: 500;
  padding: 0.1rem 0;
  font-size: 1.06rem;
}

.ft-3 i {
  padding-right: 0.5rem;
}

.Last-footer {
  background: #313131;
  text-align: center;
  color: white;
  padding: 1rem 0;
}

.Last-footer p {
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 700;
}

/* Responsive Design for Tablets and Mobile Phones */
@media (max-width: 62.5rem) { /* 992px */
  .Footer .container {
      flex-direction: column;
      align-items: center;
  }

  .Footer .row {
      padding-left: 0;
  }

  .ft-1, .ft-2, .ft-3 {
      flex: 1 1 100%;
      margin: 1rem 0;
      text-align: center;
  }

  .footer-icons {
      justify-content: center;
  }
}

@media (max-width: 48rem) { /* 768px */
  .ft-1, .ft-2, .ft-3 {
      flex: 1 1 100%;
      margin: 1rem 0;
  }

  .ft-1 h3, .ft-2 h3, .ft-3 h3 {
      font-size: 1.5rem;
  }

  .footer-icons {
      justify-content: center;
  }
}

@media (max-width: 36rem) { /* 576px */
  .Footer {
      padding: 0.5rem 0;
  }

  .ft-1, .ft-2, .ft-3 {
      flex: 1 1 100%;
      margin: 0.5rem 0;
  }

  .ft-1 h3, .ft-2 h3, .ft-3 h3 {
      font-size: 1.2rem;
  }

  .ft-1 p, .ft-2 ul a, .ft-3 p {
      font-size: 0.9rem;
  }

  .footer-icons {
      flex-wrap: wrap;
      justify-content: center;
  }

  .Last-footer p {
      font-size: 1rem;
  }
}
